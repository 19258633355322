(() => {
const tabsContainer = document.getElementsByClassName('slider-tabs')[0];
const textContainer = document.getElementsByClassName('slider-text')[0];
const imageContainer = document.getElementsByClassName('slider-image')[0];

// manual slider
Array.from(tabsContainer.children).map((tab, i, all) => {
  tab.addEventListener('click', () => {
    all.map(x => x.classList.remove('tab-active'))
    Array.from(textContainer.children).map(x => x.classList.remove('text-active'))
    Array.from(imageContainer.children).map(x => x.classList.remove('image-active'))

    tab.classList.add('tab-active')
    textContainer.children[i].classList.add('text-active')
    imageContainer.children[i].classList.add('image-active')

    stopLoop = true
  })
})

let activeIndex = 0
let stopLoop = false
let loopId = undefined

function loopSlider() {
  if (stopLoop) return

  Array.from(tabsContainer.children).map(x => x.classList.remove('tab-active'))
  Array.from(textContainer.children).map(x => x.classList.remove('text-active'))
  Array.from(imageContainer.children).map(x => x.classList.remove('image-active'))
  setTimeout(() => {
    tabsContainer.children[activeIndex].classList.add('tab-active')
    textContainer.children[activeIndex].classList.add('text-active')
    imageContainer.children[activeIndex].classList.add('image-active')
  })

  clearInterval(loopId)
  loopId = setTimeout(() => {
    activeIndex = activeIndex >= 2 ? 0 : activeIndex + 1
    loopSlider()
  }, 8000)
}


addEventListener('DOMContentLoaded', () => {
  const sliderBlock = document.getElementById('features-slider')

  let observer = new IntersectionObserver((entries, observer) => {
    // console.log('scroll observe', entries[0]);
    const entry = entries[0]
    if (entry.isIntersecting) {
      stopLoop = false
      loopSlider()
    } else {
      stopLoop = true
    }
  }, {
    root: null
  });

  observer.observe(sliderBlock)
})

})()